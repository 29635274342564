.otp {
  padding: 5px;
  font-size: 2rem;
  margin: 4px;
  width: 3rem;
  text-align: center;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
}

.container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .otp {
    padding: 5px;
    font-size: 2rem;
    margin: 4px;
    width: 2.5rem;
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .otp {
    padding: 2px;
    font-size: 1.2rem;
    margin: 2px;
    width: 1.5rem;
    text-align: center;
  }
}
